import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/error_pages.scss"
import { Link } from "gatsby"

const NotFoundPage = () => (
 <Layout>
  <SEO title="500: Server Error" />
  <section className="message msg-error">

   <h1>OOOPS!!</h1>
   <h2>Something went wrong.</h2>

   <p>Why not try refreshing your page? Or <Link to="/">contact us.</Link></p>

  </section>
 </Layout>
)

export default NotFoundPage
